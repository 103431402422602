<template>
  <div class="orders-index width-100">
    <Filters
      :filters="ordersFilters"
      filter-key="orders"
      :initial-data="filtersStatus.orders"
    />

    <!-- <div
      v-if="userPermissions && userPermissions.includes('user')"
      class="order-buttons"
    >
      <el-button
        :type="status === 'draft' ? 'primary' : 'secondary'"
        @click="getTableRowsDraft"
      >
        {{ $t('filters.draft') }}
      </el-button>
      <el-button
        :type="status === 'sent' ? 'primary' : 'secondary'"
        @click="getTableRowsSent"
      >
        {{ $t('filters.ordersSent') }}
      </el-button>
    </div> -->

    <FloatingActionButton
      v-if="canEditOrder"
      color="green"
      icon="fas fa-plus"
      :click-function="() => openCreateOrderModal = true"
      :label="$t('orders.create.createOrder')"
    />
    <CreateOrder
      v-if="openCreateOrderModal"
      :initial-data="initialOrderData"
      :is-modal-edit="isModalEdit"
      @dialogClose="handleDialogClose"
    />

    <TableWrapper
      v-if="!loading"
      :table-columns="ordersColumns"
      :column-condition="status"
      table-key="orders"
      :enable-selection="false"
      @editOrder="goToEdit"
      @completeOrder="goToFinalize"
      @showSamples="goToOrderSamples"
      @showOrderSamples="goToOrderSamples"
      @exportExcel="exportExcel"
      @deleteOrder="deleteOrder"
      @exportRawData="exportRawData"
    />
  </div>
</template>
<!-- eslint-disable no-param-reassign -->
<script>
import moment from 'moment';
import fileSaver from 'file-saver';
import { mapGetters, mapActions } from 'vuex';
import { ordersColumns, ordersFilters } from '@/config';
import { ordersApi, customersApi } from '@/api';
import Filters from '@/components/Filters.vue';
import TableWrapper from '@/components/TableWrapper.vue';
import FloatingActionButton from '@/components/FloatingActionButton.vue';
import CreateOrder from './CreateOrder.vue';

export default {
  name: 'OrdersIndex',
  components: {
    Filters,
    TableWrapper,
    CreateOrder,
    FloatingActionButton,
  },
  data() {
    return {
      loading: false,
      status: 'draft',
      ordersColumns,
      ordersFilters,
      openCreateOrderModal: false,
      isModalEdit: false,
      initialOrderData: {},
      canEditOrder: false,
    };
  },
  computed: {
    ...mapGetters([
      'filtersStatus',
      'tableFilters',
      'userPermissions',
      'loggedUser',
    ]),
  },
  created() {
    this.parseQueryStringStatus(this.$route.query);
    this.applyDefaultSorting();
    this.getTableRows(this.$route.query);
    this.checkCanEditOrder();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (to.params.orderId) {
        vm.getOrder();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    this.parseQueryStringStatus(to.query);
    this.getTableRows(to.query);
    next();
  },
  methods: {
    ...mapActions([
      'updateTableRows',
      'updateTableCount',
      'parseQueryStringStatus',
    ]),
    handleDialogClose(status) {
      this.openCreateOrderModal = false;
      if (status.success) {
        this.getTableRows();
      }
      if (this.isModalEdit) {
        this.isModalEdit = false;
      }
      if (status.order) {
        this.$router.push({ name: 'editOrder', params: { orderId: status.order.id } });
      }
    },
    // getTableRowsDraft() {
    //   if (this.status === 'draft') {
    //     this.$router.push({
    //       name: 'ordersIndex',
    //     });
    //   } else {
    //     this.$router.push({
    //       name: 'ordersIndex',
    //       query: {
    //         status: 'draft', tableKey: 'orders', perPage: 10, page: 1,
    //       },
    //     });
    //   }
    // },
    getTableRowsSent() {
      if (this.status === 'sent') {
        this.$router.push({
          name: 'ordersIndex',
        });
      } else {
        this.$router.push({
          name: 'ordersIndex',
          query: {
            status: 'sent', tableKey: 'orders', perPage: 10, page: 1,
          },
        });
      }
    },
    async getTableRows(query = null) {
      try {
        this.loading = true;
        this.status = query ? query.status : this.status;
        const ordersIndexResponse = await ordersApi.index(this.tableFilters('orders'), this.dynamicPageFilters());
        const orders = ordersIndexResponse.data.map((item) => {
          const customerExpirationDate = moment.utc(item.customerExpirationDate).valueOf();
          const currentDate = moment.utc().valueOf();
          return {
            ...item,
            customerIsExpired: currentDate > customerExpirationDate,
          };
        });
        const ordersCountResponse = await ordersApi.count(this.dynamicPageFilters());
        this.updateTableRows({ tableKey: 'orders', rows: orders });
        this.updateTableCount({ tableKey: 'orders', count: ordersCountResponse.data.count });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    dynamicPageFilters() {
      if (this.userPermissions && this.userPermissions.includes('user') && this.status) {
        return { ...this.filtersStatus.orders, status: [{ key: this.status }] };
      }
      if (typeof (this.$route.params.userId) !== 'undefined') {
        return { ...this.filtersStatus.orders, creatorId: [{ key: this.$route.params.userId }] };
      }
      return this.filtersStatus.orders;
    },
    async getOrder() {
      try {
        const order = await ordersApi.show(this.$route.params.orderId);
        this.initialOrderData = {
          name: order.data.name,
          address: order.data.address,
          vat: order.data.vat,
          sdi: order.data.sdi,
          certifiedEmail: order.data.certifiedEmail,
          fiscalCode: order.data.fiscalCode,
          isEnabled: order.data.isEnabled,
        };
        this.isModalEdit = true;
        this.openCreateOrderModal = true;
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
        this.$router.push({
          name: 'ordersIndex',
        });
      }
    },
    goToEdit(row) {
      this.$router.push({
        name: 'editOrder',
        params: { orderId: row.id },
      });
    },
    goToFinalize(row) {
      this.$router.push({
        name: 'orderSummary',
        params: { orderId: row.id },
      });
    },
    goToOrderSamples(row) {
      const filterData = {
        orderId: [{
          key: row.id,
          label: row.title,
        }],
      };
      this.$router.push({
        name: 'samplesIndex',
        query: {
          filterKey: 'samples',
          filterData: JSON.stringify(filterData),
        },
      });
    },
    async exportExcel(row) {
      try {
        this.loading = true;
        const file = await ordersApi.exportExcel(row.id);
        if (file.data) {
          return fileSaver.saveAs(URL.createObjectURL(file.data), `export-order-${row.id}.zip`);
        }
        this.loading = false;
        return file;
      } catch (error) {
        this.loading = false;
        return error;
      }
    },
    deleteOrder(row) {
      try {
        this.$confirm(this.$t('orders.delete.confirm'), this.$t('global.warning'), {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning',
          beforeClose: async (action, instance, done) => {
            if (action === 'confirm') {
              try {
                instance.confirmButtonLoading = true;
                await ordersApi.delete(row.id);
                this.$notify({ showClose: true, type: 'success', message: this.$t('orders.delete.success') });
                await this.getTableRows(this.$route.query);
                instance.confirmButtonLoading = false;
                done();
              } catch (error) {
                instance.confirmButtonLoading = false;
                done();
                this.$notify({
                  type: 'info',
                  message: this.$t('orders.delete.cancelled'),
                });
              }
            } else {
              done();
              this.$notify({
                type: 'info',
                message: this.$t('orders.delete.cancelled'),
              });
            }
          },
        });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async exportRawData(row) {
      try {
        this.loading = true;
        const file = await ordersApi.exportRawData(row.id);
        fileSaver.saveAs(file.data, `order-${row.id}-raw-data.xlsx`);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async checkCanEditOrder() {
      const customerName = this.loggedUser.customer ? this.loggedUser.customer.name : null;
      if (customerName) {
        const results = await customersApi.autocompleteFetch(customerName);
        this.canEditOrder = results.data.length > 0;
        return;
      }
      this.canEditOrder = true;
    },
    applyDefaultSorting() {
      this.$store.dispatch('updateTableSorting', {
        tableKey: 'orders',
        sorting: {
          prop: 'createdAt', order: 'descending',
        },
      });
    },
  },
};
</script>
<style lang="scss">
  .orders-index {
    .el-table__body tr:hover > td.table-cell-status {
      @each $modifier, $map in $order-statuses {
        &--#{$modifier} {
          filter: brightness(0.9);
          background-color: map-get($map, 'background');
        }
      }
    }

    .table-cell-status {
      @each $modifier, $map in $order-statuses {
        &--#{$modifier} {
          background-color: map-get($map, 'background');
          color: map-get($map, 'color');
        }
      }
    }
  }

  .order-buttons {
    margin-bottom: 2rem;
    display: flex ;
  }
</style>
