<template>
  <el-dialog
    visible
    width="25%"
    @close="abortCreate"
  >
    <ContentHeader
      slot="title"
      :title="isModalEdit ?
        $t('orders.update.updateOrder') : $t('orders.create.createOrder')"
    />
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <el-form
        ref="form"
        label-position="top"
      >
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="24">
            <FormItem
              rules="required"
              :label="$t('orders.create.title')"
              :help="$t('tooltips.orderName')"
            >
              <el-input
                v-model="formData.title"
              />
            </FormItem>
            <FormItem
              :label="$t('orders.create.customerOrderNumber')"
              :help="$t('tooltips.customerOrderNumber')"
            >
              <el-input
                v-model="formData.customerOrderNumber"
              />
            </FormItem>
            <FormItem
              v-if="userPermissions.includes('admin')"
              rules="required"
              :label="$t('orders.create.customerId')"
            >
              <el-autocomplete
                v-model="customerName"
                style="width: 100%"
                :fetch-suggestions="customersSearch"
                :trigger-on-focus="false"
                value-key="label"
                placeholder=""
                @select="handleSelectAutocomplete"
                @input="formData.customerId = ''"
              />
            </FormItem>
            <FormItem
              :label="$t('orders.create.separateInvoice')"
            >
              <el-switch
                v-model="formData.separateInvoice"
                :active-text="$t('orders.create.separateInvoiceYes')"
                :inactive-text="$t('orders.create.separateInvoiceNo')"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="24">
            <p>{{ $t('global.requiredFields') }}</p>
          </el-col>
        </el-row>
        <FormButtons>
          <el-button
            type="secondary"
            @click.prevent="abortCreate"
          >
            {{ $t('orders.create.abortCreate') }}
          </el-button>
          <el-button
            v-if="isModalEdit"
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(updateOrder)"
          >
            {{ $t('orders.update.updateOrder') }}
          </el-button>
          <el-button
            v-else
            type="primary"
            native-type="submit"
            :disabled="invalid"
            @click.prevent="handleSubmit(createOrder)"
          >
            {{ $t('orders.create.createOrder') }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ordersApi, customersApi } from '@/api';
import FormItem from '@/components/FormItem.vue';
import ContentHeader from '@/components/ContentHeader.vue';
import FormButtons from '@/components/FormButtons.vue';

const defaultData = {
  title: '',
  customerId: '',
  customerOrderNumber: '',
  separateInvoice: false,
};

export default {
  name: 'CreateOrder',
  components: {
    FormItem,
    ContentHeader,
    FormButtons,
  },
  props: {
    visible: { type: Boolean, default: false },
    isModalEdit: { type: Boolean, default: false },
    initialData: { type: Object, default: () => defaultData },
  },
  data() {
    return {
      formData: { ...defaultData },
      customerName: '',
    };
  },
  computed: {
    ...mapGetters([
      'userPermissions',
    ]),
  },
  watch: {
    initialData(data) {
      if (Object.keys(data).length > 0) {
        this.formData = { ...data };
      }
    },
  },
  methods: {
    async createOrder() {
      try {
        const orderCreatedResponse = this.userPermissions.includes('admin')
          ? await ordersApi.create({
            title: this.formData.title,
            customerId: this.formData.customerId,
            customerOrderNumber: this.formData.customerOrderNumber,
            separateInvoice: this.formData.separateInvoice,
          })
          : await ordersApi.create({
            title: this.formData.title,
            customerOrderNumber: this.formData.customerOrderNumber,
            separateInvoice: this.formData.separateInvoice,
          });
        this.$notify({ showClose: true, type: 'success', message: this.$t('orders.create.success') });
        this.formData = { ...defaultData };
        this.$emit('dialogClose', { success: true, order: orderCreatedResponse.data });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    async updateOrder() {
      try {
        await ordersApi.update(this.$route.params.orderId, {
          name: this.formData.name,
          address: this.formData.address,
          vat: this.formData.vat,
          sdi: this.formData.sdi,
          certifiedEmail: this.formData.certifiedEmail,
          fiscalCode: this.formData.fiscalCode,
          isEnabled: this.formData.isEnabled,
        });
        this.$notify({ showClose: true, type: 'success', message: this.$t('orders.update.success') });
        this.formData = { ...defaultData };
        this.$emit('dialogClose', { success: true });
      } catch (error) {
        this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
      }
    },
    abortCreate() {
      this.formData = { ...defaultData };
      this.$emit('dialogClose', { success: false });
    },
    async customersSearch(value, cb) {
      if (value.length >= 3) {
        const results = await customersApi.autocompleteFetch(value);
        const filteredResults = results.data.map(item => (
          {
            label: item.name,
            value: item.id,
          }
        ));
        cb(filteredResults);
      } else {
        cb([]);
      }
    },
    handleSelectAutocomplete(item) {
      this.formData.customerId = item.value;
    },
  },
};
</script>
